import React from "react"
import ScrollAnimation from "react-animate-on-scroll"

const SubHeader = ({ title, image }) => {
  const style = {
    backgroundImage: `url(${image})`,
  }
  return (
    <section className="subHeader">
      <div className="backgroundImageDiv" style={style}></div>
      <div className="subContainer">
        <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
          <h1>{title}</h1>
        </ScrollAnimation>
      </div>
    </section>
  )
}

export default SubHeader
