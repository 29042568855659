import { Button, Container, Grid } from "@mui/material"
import { Link } from "gatsby"
import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import Bg from "../images/svgs/BG.svg"

const Block = ({
  title,
  content,
  button1,
  button1_link,
  button2,
  button2_link,
  img,
  imgAlt,
  inverted,
}) => {
  return (
    <section className="block">
      <Bg className="bgSvg1" />
      <Bg className="bgSvg2" />
      <Container>
        <Grid
          className={inverted ? "gridContainer reverseRow" : "gridContainer"}
          container
          spacing={5}
        >
          <Grid className="contentContainer" item sm={12} lg={img ? 6 : 12}>
            <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
              {title ? <h2> {title}</h2> : ""}
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="animate__fadeIn"
              animateOnce={true}
              delay={100}
            >
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="animate__fadeIn"
              animateOnce={true}
              delay={300}
            >
              {button1 ? (
                <Button
                  component={Link}
                  to={button1_link ? button1_link : "#"}
                  variant="contained"
                  size="large"
                >
                  {button1}
                </Button>
              ) : (
                ""
              )}
              {button2 ? (
                <Button
                  component={Link}
                  to={button2_link ? button2_link : "#"}
                  variant="contained"
                  size="large"
                  className="secondary"
                >
                  {button2}
                </Button>
              ) : (
                ""
              )}
            </ScrollAnimation>
          </Grid>
          {img && <Grid item className="imageGrid" sm={12} lg={6}>
            <img src={img} alt={imgAlt || title} />
          </Grid>}
        </Grid>
      </Container>
    </section>
  )
}

export default Block
