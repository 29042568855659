import { Button, Container } from "@mui/material"
import { Link } from "gatsby"
import React from "react"
import ScrollAnimation from "react-animate-on-scroll"

const CallToAction = ({ header, content, button, button_link, img, style }) => {
  return (
    <section className="callToActionC" style={style}>
      <div
        className="backgroundImageDiv"
        style={{
          backgroundImage: `url(${img})`,
        }}
      ></div>

      <Container className="ctoContent">
        <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
          <h3>{header}</h3>
        </ScrollAnimation>
        <ScrollAnimation
          animateIn="animate__fadeIn"
          animateOnce={true}
          delay={300}
        >
          <div dangerouslySetInnerHTML={{ __html: content }} />;
        </ScrollAnimation>

        {button ? (
          <ScrollAnimation
            animateIn="animate__fadeIn"
            animateOnce={true}
            delay={350}
          >
            <Button component={Link} to={button_link} variant="contained">
              {button}
            </Button>
          </ScrollAnimation>
        ) : (
          ""
        )}
      </Container>
    </section>
  )
}

export default CallToAction
